import { Injectable } from '@angular/core';
import { User } from '../models/auth.models';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { GlobalComponent } from "../../global-component";

const AUTH_API = GlobalComponent.AUTH_API;
const AUTH_APIV2 = GlobalComponent.API_URL + GlobalComponent.AUTH_APIV2;

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable({ providedIn: 'root' })

/**
 * Auth-service Component
 */
export class AuthenticationService {

    user!: User;
    currentUserValue: any;
    ipAddress = '';

    private currentUserSubject: BehaviorSubject<User>;
    // public currentUser: Observable<User>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')!));
        // this.currentUser = this.currentUserSubject.asObservable();
        //this.getIPAddress();
    }
    getIPAddress() {
        this.http.get("https://api.ipify.org/?format=json").subscribe((res: any) => {
            this.ipAddress = res.ip;
        });
    }
    /**
     * Performs the register
     * @param email email
     * @param password password
     */
    register(email: string, first_name: string, password: string) {
        // Register Api
        return this.http.post(AUTH_API + 'signup', {
            email,
            first_name,
            password,
        }, httpOptions);
    }

    /**
     * Performs the auth
     * @param email email of user
     * @param password password of user
     */
    login(email: string, password: string) {
        let headers = this.getHeader();
        return this.http.post<any>(AUTH_API, { email, password }, { headers }).pipe(
            map(response => response) // Extract the `data` property
        );
    }
    /**
     * Logout the user
     */
    logout() {
        // logout the user
        localStorage.removeItem('permissions');
        localStorage.removeItem('classifications');
        localStorage.removeItem('leftBarMenu');
        localStorage.removeItem('configurations');
        localStorage.removeItem('actualCompany');
        localStorage.removeItem('dateFormat');
        localStorage.removeItem('currency');
        localStorage.removeItem('currentUser');
        localStorage.removeItem('token');
        this.currentUserSubject.next(null!);
    }

    /**
     * Reset password
     * @param email email
     */
    resetPassword(email: string) {
        let headers = this.getHeader();
        return this.http.post<any>(AUTH_APIV2 + '/ResetPassword', { email }, { headers }).pipe(
            map(response => response) // Extract the `data` property
        );
    }
    getTokenStatus(token: string): Observable<any> {
        const headers = this.getHeader();
        return this.http.get<any>(AUTH_APIV2 + '/ValidateToken?token=' + token, { headers }).pipe(
            map(response => response) // Extract the `data` property
        );
    }
    getHeader() {
        this.getIPAddress();
        var token = localStorage.getItem('token');

        if (!token) {
            console.error('No token found');
            token = '';
            //return new Observable<any>(); // Return an empty observable
        }

        return new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'Ip': this.ipAddress
        });
    }
    getAxiosHeader() {
        this.getIPAddress();
        var token = localStorage.getItem('token');

        if (!token) {
        console.error('No token found');
        return null; // Return null or handle as needed
        }

        const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Ip': this.ipAddress
        };

        return headers;
    }
    completeResetPassword(token: string, password: string) {
        let headers = this.getHeader();
        return this.http.post<any>(AUTH_APIV2 + '/CompleteResetPassword', { token, password }, { headers }).pipe(
            map(response => response) // Extract the `data` property
        );
    }
    reSendVerifyEmail(id: number) {
        let headers = this.getHeader();
        return this.http.post<any>(AUTH_APIV2+'/ReSendVerifyEmail?id='+id.toString(), null , { headers }).pipe(
            map(response => response) // Extract the `data` property
        );
    }
    getCreateTokenStatus(token: string): Observable<any> {
        const headers = this.getHeader();
        return this.http.get<any>(AUTH_APIV2 + '/ValidateCreateToken?token=' + token, { headers }).pipe(
          map(response => response) // Extract the `data` property
        );
    }
    completeNewPassword(token: string, password: string) {
        let headers = this.getHeader();
        return this.http.post<any>(AUTH_APIV2+'/CompleteNewPassword', {token, password} , { headers }).pipe(
            map(response => response) // Extract the `data` property
        );
    }
}

